<template>
  <div id="goodsDetail" class="fixedMode">
    <div class="mainScroll" :class="{ loading: !isInit }">
      <div class="topGoodsImgs" :class="{ loading: !isImgInit }">
        <div class="goodsImg" v-if="!isImgInit"></div>
        <div class="swiper-container" v-else>
          <div class="swiper-wrapper">
            <div
              class="swiper-slide goodsImg"
          
            >   
             <!-- v-for="(img, index) in goods.carouselimg1"
              :key="'topImg' + index" -->
              <img :src="goods.carouselimg1" alt="" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="goodsInfo">
        <p class="name" :class="{ gapLoad: !isInit }">{{ goods.name }}</p>
        <p class="desc" :class="{ gapLoad: !isInit }">{{ goods.introduction }}</p>
        <span class="saleSign gapHidden">专享折后价</span>
        <div class="prizeWrap gapLoad">
          <span class="newPrize"
            >￥<span class="num">{{ goods.currentPrice }}</span></span
          >
          <span class="oldPrize" v-if="!!goods.originalPrice"
            >￥{{ goods.originalPrice }}</span
          >
          <!-- <span class="buyNum">2005人已购买</span> -->
        </div>
      </div>
      <!-- <div class="infoBar">
       
      </div> -->
      <!-- <div class="infoBar" @click="openDiscount()">
        <p class="infoLeft">
          <span class="infoItem">选择</span>
          <span class="infoCon">{{tipText}}</span>
        </p>
        <span class="infoRight"></span>
      </div> -->
      <div class="infoBar" v-if="!!brandInfo.name">
        <p class="infoLeft brandInfo">
          <img :src="brandInfo.iconUrl" alt class="bandImg" />
          {{ brandInfo.name }}
        </p>      
        <!-- todo -->
        <!-- <span class="infoRight">关于品牌</span> -->
      </div>
      <div class=""></div>
      <div class="inforBar" v-html="goods.detail" style="white-space:pre-wrap"></div>
      <!-- <div class="infoBar" style="display:block">
         <p class="titleInfo">使用说明</p>
         <p class="infodesc">
         88元礼包券包含：<br/> 1、8折减20元，2张；<br/> 2、7.5折减10元，1张；<br/> 3、满60元减15元，2张；<br/> 4、支付立减4元，2<br/>
        </p>
        <p class="secondTitle">使用规则</p>
        <p class="infodesc">1、兑换码所兑换的优惠券仅限在南京下单使用；</p>
        <p class="infodesc">2、每个兑换码仅限一次兑换使用；</p> 
        <p class="infodesc">3、兑换码不兑换现金，用户下单结束时自动减免；</p> 
        <p class="infodesc">4、每个订单仅支持一张优惠券，优惠券不可叠加使用，不能与其他优惠折扣共享；</p> 
        <p class="infodesc">5、优惠券折扣金融不能开具发票；</p>
        <p class="infodesc">6、如需帮助请拨打客服电话：10107373；</p>  
        <p class="infodesc">7、T3出行在法律范围内保留对优惠券使用细则的最终解释权；</p>
        <p class="secondTitle">兑换使用流程</p>
        <p class="infodesc"><a style="display:block;width:100%;" href="https://SHORT.T3GO.CN/18VAXO">1、链接在浏览器打开:https://SHORT.T3GO.CN/18VAXO</a></p>
        <p class="infodesc">2、输入手机号及兑换码，点击领取；</p> 
        <p class="infodesc">3、优惠券自动绑定在T3出行优惠券账户中；</p> 
        <p class="infodesc">4、下载T3出行app可进行使用；</p> 
      </div> -->
 
      <div class="goodsCon">
        <img
          class="detailImgObj"
          :src="item.imgUrl"
          alt
          v-for="(item, index) in detailImgList"
          :key="'detailImgList' + index"
        />
      </div>
    </div>

    <div class="bottomBtn">
      <button
        type="button"
        class="buybtn"
        :class="{ dis: goodsState != 1 }"
        v-show="isInit"
        @click="buyEvent"
      >
        {{
          goodsState == 1
            ? "立即购买"
            : goodsState == 2
            ? "商品已售罄"
            : "商品已下架"
        }}
      </button>
    </div>
    <transition name="selectMask-show">
      <div class="selectDiscount" v-if="disCountSelect">
        <div class="pullWrap">
          <!-- <div class="pullWrapbg"> -->
          <!-- </div> -->
          <div class="pullTitle">选择优惠券
             <span class="closeBtn" @click="disCountSelect = false"></span>
          </div>
          <div class="discountModule">
            <div class="discountItem" v-for="(discount,index) in disCountList" :key="index" ref="discountCode" @click="selectDiscount(index,discount)">
              <div class="itemLeft">
                <p class="itemNum">{{discount.couponPrice}}</p>
                <p class="itemLimit">{{'满'+discount.useMinPrice+'可使用'}}</p>
              </div>
              <div class="discountCenter">
                <div class="discountType">
                  <div class="name">  <span class="type">{{discount.ctype=='0'?'通用券':'商品券'}}</span>{{discount.couponTitle}}</div>
                </div>
                <ul class="limit">
                  <li>不可叠加使用</li>
                  <li>{{(discount.endTime).split(' ')[0]+'到期'}}</li>
                </ul>
              </div>
              <div class="btnWrap"> <div class="btn">已领取</div></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 更多参数拉窗 -->
    <transition name="selectMask-show">
      <div class="selectMask" v-if="showSelectMask">
        <div class="pullWrap">
          <div class="pullWrapbg">
            <span class="closeBtn" @click="showSelectMask = false"></span>
            <div class="topGoodInfo">
              <img
                class="goodsImg"
                v-if=" goods.coverimg"
                :src="
                  goods.coverimg
                 "
                alt
              />
               <!-- curSku && !!curSku.imgUrl ? curSku.imgUrl : imgList[0].imgUrl
                " -->
              <div class="goodsPrizeWrap">
                <p class="nowPrzie">
                  {{ !!goods.originalPrice ? "折后" : "" }}￥<span
                    class="num"
                    >{{ goods.currentPrice }}</span
                  >
                </p>
                <p class="oldPrzie" v-if="!!goods.originalPrice">
                  ￥{{ goods.originalPrice }}
                </p>
              </div>
            </div>
            <!-- <div class="serviceTips">
              <span class="left">服务保障</span>
              <span class="right">7天无理由退换货</span>
            </div> -->
            <template v-for="(attrs, attrId) in attrList">
              <p class="selectTips" :key="'selectTips' + attrId">
                请选择{{ attrTxtObj[attrId] }}
              </p>
              <ul class="selectUl" :key="'selectUl' + attrId">
                <li
                  class="selectUl-li"
                  :class="{
                    cur: attrState[attrId] == attrValId,
                    dis: !selectUlLiState(attrId, attrValId)
                  }"
                  v-for="attrValId in attrs"
                  :key="'selectUl-li' + attrValId"
                  @click="checkSelect(attrId, attrValId, attrState[attrId])"
                >
                  {{ attrValTxtObj[attrValId] }}
                </li>
              </ul>
            </template>
            <div class="selectBar">
              <span class="selectItem">购买数量</span>
              <numStepper
                :defaultValue="buyCount"
                :max="goods.buyLimit"
                @change="buyCountUpdate"
              ></numStepper>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import "@/assets/css/swiper.min.css";
import Swiper from "@/utils/swiper.min.js";
import numStepper from "@/components/goods/numStepper";
export default {
  name: "goodsDetail",
  data() {
    return {
      spuId: this.$route.query.spuId,
      sceneNo: this.$route.query.sceneNo,
      productId:this.$route.query.id || this.$route.query.productId,
      repurchaseId: this.$route.query.repurchaseId,
      isInit: true, //  主参数初始化标识
      isImgInit: true, //  图片是否初始化标识
      isAttrInit: false, //  参数属性是否初始化标识
      showSelectMask: false, //  选择规格弹层
      goods: {
          // name:'T3出行-88元打车礼包券',
          // brief:'T3出行-88元打车礼包券',
          // originalPrice:'',
          // currentPrice:'0.01'
      }, //  商品主要参数
      skuList: [], // sku列表
      imgLoadCount: 0, //  顶部图片加载计数
      imgList: [
          {imgUrl:'http://dspcloud.spay365.com/4b7d5e0491daece71cc9fe1ffc81b9e45cc1e66b.jpg'}
      ], //  顶部图片列表
      attrLoadCount: 0, //  商品属性接口加载计数
      tempCommonAtrr: [], //  属性临时存储 通用属性
      tempSkuAtrr: {}, //  属性临时存储 sku属性
      attrList: {
        // 属性id1: [值1，值2，值3]
      }, //  属性与属性值对应列表
      attrState: {
        // 属性id1: ""
      }, //  属性表选中状态
      goodsAttrMap: {
        // 商品id: {
        //   属性id1: 属性值1
        // }
      }, //  商品与属性值对应关系
      attrTxtObj: {
        // productAttributeId: attributeOptionValue
      }, //  记录商品属性id与名称对应
      attrValTxtObj: {
        // attributeOptionId: attributeOptionValue
      }, //  记录商品属性值id与名称对应
      goodsState: 1, //  -1加载中 0已下架 1正常状态 2已售罄
      selectGoods: [], //  已选属性指向商品交集 skuIds
      curSku: null, //  当前选中的sku对象
      buyCount: 1, //  购买数量
      detailImgList: [], //  详情图片
      brandInfo: {}, //  商品品牌信息
      uid:'',
      disCountSelect:false,
      disCountList:[],
      couponId:'',
      tipText:'请选择优惠券'
    };
  },
  components: {
    numStepper
  },
  watch: {
    //  已选属性指向的商品
    // selectGoods(val) {
    //   if (val.length !== 1) return;
    //   //  指向商品的选择条件是否齐全
    //   let id = val[0];
    //   const attrs = this.goodsAttrMap[id];
    //   let keys = Object.keys(attrs); // 商品属性
    //   if (!keys.find((key) => attrs[key] != this.attrState[key])) {
    //     this.setCurSku(id);
    //   }
    //}
  },
  created() {
    this.getGoods();
    this.getDiscountList();
    // if (!this.spuId || !this.sceneNo) {
    //   this.$toast({
    //     message: "该商品不存在",
    //     position: "bottom"
    //   });
    // } else {
    //   this.getGoodsDetail();
    // }
  },
  mounted(){
      this.initSwipe();
  },
  methods: {
    getGoods(){
      this.request.get('/api/scm/product/virtual/detail',{id:this.productId}).then(res =>{
        console.log(res)
        const {uid,pvName,realPrice} = res;
        this.goods = res;
        this.goods.name = pvName;
        this.goods.currentPrice = realPrice;
        this.uid =uid;
      })
    },
    //  获取商品详情
    getGoodsDetail() {
      this.request
        .get("/api/scm/sale/scene/spu/detail", {
          sceneNo: this.sceneNo,
          spuId: this.spuId
        })
        .then((res) => {
          if (res) this.renderGoods(res);
        });
    },
    //  加载商品图片
    getGoodsImg() {
      this.imgLoadCount++;
      this.request
        .get("/api/scm/product/gallery/list", {
          spuId: this.spuId,
          skuId:
            this.imgLoadCount == 1
              ? undefined
              : this.skuList[this.imgLoadCount - 2].skuId, //  imgLoadCount计数1一定为spu图片 后续查找sku图片
          typeId: this.global.GOODS_SWIPER_IMG_TYPE //  商品详情轮播图片
        })
        .then((res) => {
          if (res && res.length) {
            let temp = [];
            let tempUrl = "";
            res.forEach((img) => {
              if (img.isPrimary) {
                //  判断主图放在首位
                temp.unshift(img);
                tempUrl = img.imgUrl;
              } else {
                temp.push(img);
              }
            });
            this.imgList = this.imgList.concat(temp);
            if (this.imgLoadCount != 1) {
              //  sku的图
              this.skuList[this.imgLoadCount - 2].imgUrl =
                tempUrl || (temp[0] && temp[0].imgUrl) || "";
            }
          }
          if (this.imgLoadCount == this.skuList.length + 1) {
            //  所有商品图片已经加载完毕
            this.isImgInit = true;
            this.$nextTick(() => {
              this.initSwipe();
            });
          } else {
            this.getGoodsImg();
          }
        });
    },
    //  获取商品属性参数
    getGoodsAttr() {
      this.attrLoadCount++;
      this.request
        .get("/api/scm/product/attribute/list", {
          spuId: this.spuId,
          skuId:
            this.attrLoadCount == 1
              ? undefined
              : this.skuList[this.attrLoadCount - 2].skuId //  原理同getGoodsImg
        })
        .then((res) => {
          if (res && res.length) {
            if (this.attrLoadCount != 1)
              this.tempSkuAtrr[this.skuList[this.attrLoadCount - 2].skuId] = [];
            res.forEach((attr) => {
              if (attr.isSaleAttr == 1) {
                //  只看销售属性
                const {
                  skuId,
                  attributeOptionId,
                  attributeOptionValue,
                  productAttributeId,
                  productAttributeName
                } = attr;
                let temObj = {
                  attributeOptionId,
                  attributeOptionValue,
                  productAttributeId,
                  productAttributeName
                };
                //  如果属性设置重复就覆盖之前已有的（因为后台没有做限制 以下循环判断是防止人为出错 各种属性结合只能对应一个sku）
                if (this.attrLoadCount == 1) {
                  let tmpIndex = this.tempCommonAtrr.findIndex(
                    (item) => productAttributeId == item.productAttributeId
                  );
                  if (tmpIndex > -1) {
                    // 筛选已经有的属性，新值覆盖旧值
                    this.tempCommonAtrr[tmpIndex] = temObj;
                  } else {
                    this.tempCommonAtrr.push(temObj);
                  }
                } else {
                  //  sku的属性
                  //  如果sku包含通用属性则无视通用属性 否则增加通用属性值
                  let curSkuObj = this.tempSkuAtrr[skuId];
                  let tmpIndex = curSkuObj.findIndex(
                    (item) => productAttributeId == item.productAttributeId
                  );
                  if (tmpIndex > -1) {
                    // 筛选已经有的属性，新值覆盖旧值
                    curSkuObj[tmpIndex] = temObj;
                  } else {
                    curSkuObj.push(temObj);
                  }
                }
              }
            });
          }
          if (this.attrLoadCount == this.skuList.length + 1) {
            if (this.tempCommonAtrr.length) {
              for (let id in this.tempSkuAtrr) {
                let obj = this.tempSkuAtrr[id]; //  判断该sku是否有通用属性 没有有就加上
                this.tempCommonAtrr.forEach((item) => {
                  let findIndex = obj.findIndex(
                    (ii) => ii.productAttributeId == item.productAttributeId
                  );
                  if (findIndex == -1) obj.push(item);
                });
              }
            }
            //  所有商品属性已经加载完毕
            this.renderAttr();
          } else {
            this.getGoodsAttr();
          }
        });
    },
    //  渲染加载商品详情
    renderGoods(goods) {
      this.goods = goods;
      this.goods.skuList &&
        this.goods.skuList.forEach((sku) => {
          //  筛选出有库存的
          if (sku.skuStock && sku.skuStock.stock) {
            sku.stock = sku.skuStock.stock;
            //  根据库存+限购计算出商品最多可购买数量
            sku.limitCount = !!sku.isLimitBuy
              ? Math.min(sku.stock, sku.isLimitBuy)
              : sku.stock;
            this.skuList.push(sku);
          }
        });
      //  如果this.skuList没有内容说明库存为0 没有可售
      this.goodsState =
        this.goods.status != 1
          ? this.goods.status
          : !this.skuList.length
          ? 2
          : 1;
      this.isInit = true;
      this.getGoodsImg();
      this.getGoodsDetailImg();
      this.getBrand();
      //  没有sku即没有可购买产品 不需要加载属性
      if (this.skuList.length) {
        this.getGoodsAttr();
      } else {
        this.$toast({
          message: "该商品已售罄",
          position: "bottom"
        });
      }
    },
    //  商品详情内说明图片
    getGoodsDetailImg() {
      this.request
        .get("/api/scm/product/gallery/list", {
          spuId: this.spuId,
          typeId: this.global.GOODS_DETAIL_IMG_TYPE // 商品详情内说明图片
        })
        .then((res) => {
          this.detailImgList = res || [];
        });
    },
    //  获取品牌详情
    getBrand() {
      if (!this.goods.brandId) return;
      this.request
        .get("/api/scm/product/brand/detail", {
          brandId: this.goods.brandId
        })
        .then((res) => {
          this.brandInfo = res || {};
        });
    },
    //  渲染商品属性
    renderAttr() {
      if (Object.keys(this.tempSkuAtrr).length) {
        for (let skuId in this.tempSkuAtrr) {
          let attrObj = {};
          this.tempSkuAtrr[skuId].forEach((item) => {
            //  遍历当前商品的属性值
            let attrArr = this.attrList[item.productAttributeId] || [],
              attrValId = Number(item.attributeOptionId);
            if (attrArr.indexOf(attrValId) < 0) {
              attrArr.push(attrValId);
              this.attrList[item.productAttributeId] = attrArr;
              //  加载静态属性id文字对应关系
              this.attrTxtObj[item.productAttributeId] =
                item.productAttributeName;
              this.attrValTxtObj[attrValId] = item.attributeOptionValue;
              //  选项初始化属性表状态
              if (!this.attrState.hasOwnProperty(item.productAttributeId))
                this.attrState[item.productAttributeId] = "";
            }
            attrObj[item.productAttributeId] = attrValId;
          });
          //  初始化商品与属性值对应关系
          this.goodsAttrMap[skuId] = attrObj;
        }
      } else {
        console.log("没有sku属性?");
        //  没有sku属性
        this.setCurSku(this.skuList[0]);
      }
      this.isAttrInit = true;
      //  是否需要判断复购
      if (!!this.repurchaseId) this.repurchaseGoods();
    },
    // 初始化轮播图
    initSwipe() {
      new Swiper(".swiper-container", {
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination"
        },
        resistanceRatio: 0
      });
    },
    //  立即购买按钮
    buyEvent() {
      if (this.showSelectMask) {
        // if (!this.curSku) {
        //   this.$toast({
        //     message: "请将商品规格选择完整",
        //     position: "bottom"
        //   });
        //   return;
        // }
        // 组合选中商品属性详情
        let curSkuAttr = {};
        for (let attrId in this.attrState) {
          let attrValId = this.attrState[attrId];
          if (!!attrValId) {
            let val = {};
            val[attrValId] = this.attrValTxtObj[attrValId];
            curSkuAttr[attrId] = {
              txt: this.attrTxtObj[attrId],
              val
            };
          }
        }
       let orderId = this.global.createOrderId(
        this.uid,
        '01'
      );
        //进行虚拟商品下单
        // this.request.post('/api/scm/order/virtual/virtual/create',{bizId:orderId,
        //     sceneNo:this.$route.query.sceneNo,
        //     couponId:this.couponId,
        //     placeVirtualDTOList:[{
        //     productId: this.productId,
        //     placeNum: this.buyCount
        // }]}).then(res=>{
        //     if(res){
        //     sessionStorage.setItem(`${orderId}Order`, JSON.stringify(res));
        //     // sessionStorage.setItem(`${orderId}Order`, JSON.stringify(this.goods))
        //     this.$router.push({
        //     path: "/h/scm/virtual/virtualForm",
        //     query: {
        //       sceneNo: this.sceneNo,
        //       productId: this.productId,
        //       bizId:orderId
        //     }
        //   });
        //     }
        // })
        //  存储信息至缓存sessionStorage用于订单页面
        sessionStorage.setItem(
          `spuBuy${this.productId}`,
          JSON.stringify({
            spuInfo: this.goods,
            skuInfo: this.curSku,
            imgUrl:this.goods.coverimg,
            buyCount: this.buyCount,
            skuAttr: curSkuAttr
          })
        );
        setTimeout(() => {
            this.$router.push({
            path: "/h/scm/virtual/virtualForm",
            query: {
              sceneNo: this.sceneNo,
              productId: this.productId,
              bizId:orderId
            }
          });
        }, 10);
      } else {
        this.openGoodsAttrs();
      }
    },
    //  打开商品参数
    openGoodsAttrs() {
    //   if (this.goodsState != 1) {
    //     this.$toast({
    //       message: this.goodsState == 2 ? "该商品已售罄" : "该商品已下架",
    //       position: "bottom"
    //     });
    //     return;
    //   }
    //   if (!this.isAttrInit) {
    //     this.$toast({
    //       message: "数据加载中，请稍候",
    //       position: "bottom"
    //     });
    //     return;
    //   }
      this.showSelectMask = true;
    },
    getUid(){
      this.request.post('/api/n/user/uid',{}).then(res=>{
        console.log(res)
      })
    },
    //  验证商品选择(属性id，属性值id, 当前属性选中值)
    checkSelect(attrId, valId, curValId) {
      //  拦截不可选选项
      if (!this.selectUlLiState(attrId, valId)) return;
      this.attrState[attrId] = valId == curValId ? "" : valId;
      this.curSku = null;
      this.selectGoods = this.filterSkuId(this.attrState);
    },
    //  是否可选状态
    selectUlLiState(attrId, attrValId) {
      if (!this.selectGoods.length) return true;
      //  传入选项与当前选中状态相组合 如果有符合条件的商品 则该属性选项可选
      const tempState = Object.assign({}, this.attrState);
      tempState[attrId] = attrValId;
      return this.filterSkuId(tempState).length;
    },
    //  根据传入条件筛选出符合条件的商品
    filterSkuId(tempState) {
      let tempArr = [];
      for (const skuId in this.goodsAttrMap) {
        let flag = true;
        for (const id in tempState) {
          if (tempState[id] && tempState[id] !== this.goodsAttrMap[skuId][id]) {
            flag = false;
            break;
          }
        }
        if (flag) tempArr.push(skuId);
      }
      return tempArr;
    },
    //  设置当前选中商品（设置拉窗显示图片, 购买限制）
    setCurSku(curSku) {
      if (typeof curSku == "string") {
        //  传来字符串 说明时id
        this.curSku = this.skuList.find((item) => item.skuId == curSku);
      } else {
        this.curSku = curSku;
      }
    },
    //  购买数量更新
    buyCountUpdate(val) {
      this.buyCount = val;
    },
    //  复购
    repurchaseGoods() {
      //  如果有缓存可能是从订单确认页面跳转回来 不做复购处理
      if (!!sessionStorage.getItem(`spuBuy${this.spuId}`)) return;
      //  查找现有skuId 是否存在
      if (
        Object.keys(this.tempSkuAtrr).length &&
        !this.tempSkuAtrr[this.repurchaseId]
      ) {
        this.$toast({
          message: "复购商品发生变化，请重新选择",
          position: "bottom"
        });
      } else {
        this.openGoodsAttrs();

        this.tempSkuAtrr[this.repurchaseId] &&
          this.tempSkuAtrr[this.repurchaseId].forEach((item) => {
            this.checkSelect(
              item.productAttributeId,
              item.attributeOptionId,
              ""
            );
          });
      }
    },
    //获取优惠券列表
    getDiscountList(){
      this.request.post('/api/scm/store/coupon/user/list',{fisVproductId:this.$route.query.id}).then(res =>{
        console.log(res)
        if(res.list.length!=0){
          this.disCountList = res.list;
        }else{
           this.tipText = '暂无可用优惠券'
        }
       
      })
    },
    //选择优惠券
    selectDiscount(index,discount){
      const {id,couponTitle} = discount;
      var discountCard = this.$refs.discountCode;
      for(let i =0; i<discountCard.length;i++){
        discountCard[i].className = 'discountItem';
      }
      discountCard[index].className = 'discountSelItem';
      this.couponId = id;
      this.tipText = '您已选择'+couponTitle;
    },
    openDiscount(){
      if(this.disCountList.length=='0'){return}
      this.disCountSelect = true;
    }
  }
};
</script>
<style lang="scss">
#goodsDetail {
  .topGoodsImgs {
    .swiper-pagination-bullet {
      background: #fff;
      width: 13px;
      height: 8px;
      border-radius: 8px;
      opacity: 0.6;
      transition: all 0.5s;
      &.swiper-pagination-bullet-active {
        width: 20px;
        opacity: 1;
      }
    }
  }
  .goodsCon img {
    max-width: 100%;
    display: block;
  }
  .loading {
    .gapLoad {
      span {
        visibility: hidden;
      }
    }
    .infoBar,
    .goodsCon {
      display: none;
    }
  }
}
</style>
<style lang="scss" scoped>
.fixedMode {
  background: #efefef;
}
.topGoodsImgs {
  overflow: hidden;
  .goodsImg {
    position: relative;
    overflow: hidden;
    padding-top: 100%;
    font-size: 0;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
}
.goodsInfo {
  border-top: 1px solid #efefef;
  padding: 28px 30px 30px;
  background: #fff;
  font-size: 0;
  .name {
    min-height: 38px;
    line-height: 38px;
    font-size: 36px;
    font-weight: bold;
  }
  .desc {
    min-height: 30px;
    line-height: 30px;
    font-size: 24px;
    margin: 15px 0 16px;
    color: #666;
  }
  .saleSign {
    line-height: 35px;
    font-size: 22px;
  }
  .prizeWrap {
    margin-top: 15px;
    min-height: 38px;
    overflow: hidden;
    position: relative;
    line-height: 38px;
    .newPrize {
      font-size: 26px;
      color: #ea3d39;
      display: inline-block;
      vertical-align: bottom;
      .num {
        font-size: 48px;
      }
    }
    .oldPrize {
      font-size: 22px;
      color: #999;
      margin-left: 16px;
      display: inline-block;
      vertical-align: bottom;
      text-decoration: line-through;
    }
    .buyNum {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 24px;
      color: #999;
      line-height: 1;
    }
  }
}
.inforBar{
  margin-top: 20px;
  background: #fff;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  padding: 30px 30px;
  font-size: 24px;
}
p{
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
.infoBar {
  margin-top: 20px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  .infodesc{
    // padding: 30px 0;
    line-height: 42px;
    color: #333;
    font-size: 28px;
  }
  .secondTitle{
    font-size: 28px;
    line-height: 28px;
    // color: #000;
    font-weight: bold;
    padding-top: 40px;
    padding-bottom: 10px;
  }
  .titleInfo{
    font-size: 36px;
    line-height: 36px;
    color: #000;
    font-weight: bold;
    padding-top: 55px;
    padding-bottom: 15px;
  }
  .infoLeft {
    line-height: 85px;
    height: 85px;
    font-size: 26px;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    .infoItem {
      min-width: 75px;
      display: inline-block;
      margin-right: 5px;
      color: #666;
    }
    .infoCon {
      color: #333;
      &.discount {
        color: #ea3f3b;
      }
    }
    &.brandInfo {
      padding: 30px 0;
      line-height: 66px;
      height: 66px;
      display: flex;
      align-items: center;
      color: #333;
      font-size: 28px;
      .bandImg {
        width: auto;
        height: 66px;
        display: block;
        margin-right: 20px;
      }
    }
  }
  .infoRight {
    line-height: 48px;
    padding-right: 35px;
    min-height: 48px;
    background: url(~@/assets/icons/right-arrow.png) right center / auto 25px
      no-repeat;
    font-size: 24px;
    color: #666;
    &.nolink {
      background: none;
    }
  }
}
.goodsCon {
  overflow: hidden;
}
.bottomBtn {
  height: 160px;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  position:relative;
  z-index: 11;
  .buybtn {
    width: 690px;
    height: 96px;
    display: block;
    border-radius: 69px;
    background: linear-gradient(to right, #f62300, #fa5421);
    color: #fff;
    font-size: 36px;
    &.dis {
      background: #ccc;
    }
  }
}
.selectMask {
  bottom: 160px;
}
.pullWrapbg {
  .closeBtn {
    width: 55px;
    height: 55px;
    display: block;
    background: url(~@/assets/icons/closeBtn.png) left bottom / 35px auto
      no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .topGoodInfo {
    height: 160px;
    display: flex;
    margin-bottom: 30px;
    .goodsImg {
      width: 160px;
      height: 160px;
      display: block;
      border-radius: 10px;
      margin-right: 22px;
      object-fit: cover;
    }
    .goodsPrizeWrap {
      flex: 1;
      align-self: flex-end;
      font-size: 0;
      .nowPrzie {
        padding: 0 20px;
        display: inline-block;
        height: 50px;
        line-height: 50px;
        border-radius: 50px;
        background: #ea3d39;
        color: #fff;
        font-size: 22px;
        .num {
          font-size: 30px;
        }
      }
      .oldPrzie {
        font-size: 22px;
        color: #999;
        text-decoration: line-through;
        margin-top: 13px;
      }
    }
  }
  .serviceTips {
    margin: 30px 0;
    height: 60px;
    border-radius: 8px;
    background: #f2f4f5;
    padding: 0 30px;
    display: flex;
    font-size: 26px;
    justify-content: space-between;
    align-items: center;
    .left {
      color: #666;
    }
    .right {
      color: #333;
    }
  }
  .selectTips {
    line-height: 47px;
    font-size: 28px;
  }
  .selectUl {
    list-style: none;
    display: block;
    font-size: 0;
    padding-bottom: 14px;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
    .selectUl-li {
      display: inline-block;
      padding: 5px 20px;
      line-height: 50px;
      border-radius: 8px;
      background: #f2f4f5;
      margin: 15px 20px 15px 0;
      font-size: 26px;
      img {
        height: 50px;
        width: 50px;
        display: inline-block;
        border-radius: 6px;
        object-fit: cover;
        margin-right: 12px;
        vertical-align: bottom;
      }
      &.cur {
        background: #fef6f6;
        color: #f24d4a;
        /* prettier-ignore */
        box-shadow: 0 0 0 1PX #f24d4a inset;
      }
      &.dis {
        opacity: 0.6;
        color: #999;
      }
    }
  }
  .selectBar {
    height: 152px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .selectItem {
      font-size: 28px;
    }
  }
}
.selectDiscount{
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 750px;
    height: 100vh;
    .pullWrap{
      width: 750px;
      position: absolute;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      max-height: 100%;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;
      .pullTitle{
        width: 750px;
        height: 100px;
        font-size: 32px;
        line-height: 100px;
        text-align: center;
        color: #000;
        font-weight: 500;
        border-bottom: 1px solid #000;
        position: relative;
        .closeBtn {
          width: 55px;
          height: 55px;
          display: block;
          background: url(~@/assets/icons/closeBtn.png) left bottom / 35px auto
                    no-repeat;
          position: absolute;
          right: 10px;
          top: 5px;
          z-index: 1;
        }
      }
      .discountModule{
         width: 710px;
         height: 600px;
        //  border-radius: 20px;
         display: flex;
         display: -webkit-flex;
         background: #f5f5f5;
         margin:0 auto;
         overflow: auto;
        //  margin-bottom: 20px;
        .discountSelItem{
          border: 1px solid yellow;
        }
        .discountItem,.discountSelItem{
          width: 710px;
          height: 160px;
          margin:20px 0;
          background: #fff;
          box-sizing: border-box;
          display: flex;
          display: -webkit-flex;
          justify-content: space-between;
          .itemLeft{
            width: 200px;
            height: 160px;
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            .itemNum{
              font-size: 40px;
              font-weight: bold;
              position: relative;
              margin-bottom: 10px;
            }
            .itemNum::before{
              content: '￥';
              position: absolute;
              font-size: 18px;
              left: -20px;
              bottom: 8px;
              z-index: 1;
            }
          }
          .discountCenter{
            width: 320px;
            height: 160px;
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            justify-content: space-around;
            // align-items: center;
            font-size: 24px;
            .discountType{
                // display: flex;
                // display: -webkit-flex;
                .type{
                  font-size: 24px;
                   padding: 5px;
                   display: inline-block;
                   background: rgba(0, 0, 0, 0.4);
                   }
                .name{
                     font-size: 28px;
                    }
            }
            .limit{
              li{
                // list-style: disc;
              }
            }
            
          }
          .btnWrap{
            width: 190px;
            height: 160px;
            display: flex;
            display: -webkit-flex;
            justify-content: center;
            align-items: center;
            .btn{
            width: 100px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 24px;
            color:rgb(200, 173, 172);
            border: 1px solid red;
            border-radius: 100px;
          }
          }
          
        }
      }
    }

}
</style>